
import {computed, defineComponent, ref} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {ModelAddress} from "@/core/entity/IAddress";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {PAYMENT_METHOD, PAYMENT_STATUS, PAYMENT_STATUS_PENDING, PAYMENT_SUB_TYPE} from "@/core/config/Constant";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";
import {HandleState} from "@/core/composite/composite";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Row from "@/components/base/common/Row.vue";
import PaymentService from "@/core/services/PaymentService";
import router from "@/router";
import ExchangeRateService from "@/core/services/ExchangeRateService";
import LocationService from "@/core/services/LocationService";
import {Mutations} from "@/store/enums/StoreEnums";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import InvoiceService from "@/core/services/InvoiceService";
import FormAddress from "@/components/base/address/FormAddress.vue";
import FormCurrency from "@/components/base/currency/FormCurrency.vue";

export default defineComponent({
  name: "PaymentFormCreate",
  components: {FormCurrency, FormAddress, DateTimeSelect, Row, CurrencyFormat, BaseSelect, BaseRadio, BaseForm, Field},
  props: {
    invoice: {type: Object, required: true}
  },
  setup(props) {
    const sanctionsCheck = ref(false);
    const user = computed(() => store.getters.currentUser);
    const invoiceRef = ref(props.invoice);
    const paidAmount = ref(0);
    const unPaid = ref(0);
    const currencies = computed(() => store.state.ExchangeRateModule.currencies);
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const submitting = ref(false);
    const model = ref([{
      type: 'SERVICE',
      name: '',
      address: new ModelAddress(),
      amount: 0,
      subType: 'OTHER',
      status: 'PENDING',
      currency: invoiceRef.value.currency,
      amountInvCurrency: 0,
      method: 'NA',
      number: '',
      paidThrough: '',
      paidDt: new Date()
    }]);

    const addLine = () => {
      model.value.push({
        type: 'SERVICE',
        name: '',
        address: new ModelAddress(),
        amount: 0,
        subType: 'OTHER',
        status: 'PENDING',
        currency: invoiceRef.value.currency,
        amountInvCurrency: 0,
        method: 'NA',
        number: '',
        paidThrough: '',
        paidDt: new Date()
      })
    }

    const calculateAmountInvCurrency = async (index, item) => {
      submitting.value = true;
      if (item.currency === invoiceRef.value.currency) {
        item.amountInvCurrency = item.amount;
      } else {
        const exchanged = await ExchangeRateService.exchangeRate(item.currency, invoiceRef.value.currency, item.amount);
        item.amountInvCurrency = exchanged.converted;
      }
      submitting.value = false
      model.value[index] = item
      calculatePaid();
    }

    const loadSanctionsCheck = (id) => {

      InvoiceService.sanctionCheck(id).then(res=>{
        sanctionsCheck.value = res.result;
      })
    }

    loadSanctionsCheck(props.invoice.id)

    const calculateAmount = async (index, item) => {
      if (item.amount > 0) return;
      submitting.value = true;
      if (item.currency === invoiceRef.value.currency) {
        item.amount = item.amountInvCurrency;
      } else {
        const exchanged = await ExchangeRateService.exchangeRate(invoiceRef.value.currency, item.currency, item.amountInvCurrency);
        item.amount = exchanged.converted;
      }
      submitting.value = false
      model.value[index] = item
      calculatePaid();
    }

    const calculatePaid = () => {
      const totalPaid = model?.value?.map(item => Number(item.amountInvCurrency)).reduce((prev, curr) => prev + curr, 0);
      paidAmount.value = totalPaid + invoiceRef.value.paid;
      unPaid.value = invoiceRef.value.payable - paidAmount.value;
    }
    calculatePaid();
    const submit = () => {
      submitting.value = true;
      const data = {invoiceId: invoiceRef.value.id, items: model.value}
      PaymentService.create<any>(data).then(() => {
        router.push({
          name: "invoicePayments",
          params: {id: invoiceRef.value.id}
        });
      }).finally(() => {
        submitting.value = true;
      })
    }
    return {
      user,
      model,
      submitting,
      PAYMENT_SUB_TYPE,
      PAYMENT_METHOD,
      PAYMENT_STATUS,
      countries,
      states,
      paidAmount,
      ...HandleState(),
      unPaid,
      calculatePaid,
      invoiceRef,
      submit,
      addLine,
      calculateAmountInvCurrency,
      currencies,
      calculateAmount,
      sanctionsCheck,
      PAYMENT_STATUS_PENDING,
    }
  },
  methods: {

    onStateNameSelect(stateName, index) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name) this.model[index].address.stateName = name
    },
    onCountryNameSelect(countryName, index) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) this.model[index].address.countryName = name
    },

    async onTypeChange(type, index) {
      if (type === 'PROVIDER' && this.invoice.provider) {
        this.model[index].name = this.invoice.provider.name
        this.model[index].name = this.invoice.provider.name
        this.model[index].address = this.invoice.provider.address;
        const state = this.model[index].address.stateCode
        await LocationService.listByCode(this.model[index].address.countryCode).then(res => {
          const name = res.find((item) => item.code === state)?.name
          if (name) this.model[index].address.stateName = name
          store.commit(Mutations.SET_LOCATION_STATE, res);
        })
      } else if (type === 'PATIENT' && this.invoice.insured) {
        this.model[index].name = this.invoice.insured.person.name
        //todo : patient address;
      }
    },
    onCancel() {
      this.$router.go(-1)
    },
  }
})
