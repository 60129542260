
import { computed, defineComponent, onMounted } from "vue";
import { LoadEntity } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import { onUpdated } from "@vue/runtime-core";
import PaymentFormCreate from "@/views/payment/PaymentFormCreate.vue";

export default defineComponent({
  name: "Create",
  components: {PaymentFormCreate},
  setup() {
    const invoice = computed(() => store.state.InvoiceModule.invoice)
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Add Payment', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Add Payment'}
      ])
    })

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Add Payment', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Add Payment'}
      ])
    })
    return {
      ...LoadEntity(Actions.LOAD_INVOICE),
      invoice
    }
  },

})
